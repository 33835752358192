import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Collapsible from 'react-collapsible';

import { loadCardDetails, sendNotification } from '../../store/actions/cardActions'

var QuillDeltaToHtmlConverter = require('quill-delta-to-html').QuillDeltaToHtmlConverter;


const ref = React.createRef();

class TabType3 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cardID: this.props.match.params.cardID,
			error: {},
		}
	}

	componentDidMount() {
		// Send can open notification //
	}
	render() {
		let { tabInfo } = this.props

		let { cardID } = this.state

		let html;

		try {
			var converter = new QuillDeltaToHtmlConverter(tabInfo.contents.content, {});
			html = converter.convert();
		} catch (error) {

		}

		return (
			<div className="single-tab type-3">
				<Collapsible
					onOpen={() => {
						this.props.sendNotification({
							"card": tabInfo.CardID,
							"type": "tab",
							"type_id": tabInfo.TabID,
						})
					}}
					trigger={tabInfo.tab_title}>
					<div className="recruiting-profile"
						dangerouslySetInnerHTML={{
							__html: html
						}}></div>
				</Collapsible>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
})
const mapDispatchToProps = ({
	sendNotification,
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabType3))
