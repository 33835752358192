import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Collapsible from 'react-collapsible';

import { loadCardDetails, sendNotification } from '../store/actions/cardActions'

const ref = React.createRef();

class GuestForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: '',
			cardID: this.props.match.params.cardID,
			page: 1,
			password: '',
			error: {},
			highlighVideo: null,
			highlightPreview: null,
			allowForm: true,
		}
	}

	componentDidMount() {
		// Send can open notification //
		let { cardID } = this.state

	}

	_changeHandler = event => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}
	_handleSubmit = event => {
		event.preventDefault()

		let { firstName, lastName, email, phone } = this.state
		var guestUser = { firstName, lastName, email, phone }

		// set data to local store
		localStorage.setItem('guestUser', JSON.stringify(guestUser))
		console.log(guestUser)

		this.setState({ allowForm: false })
	}
	render() {
		let { cardState, pageData, auth, tabInfo } = this.props
		let { firstName, lastName, email, phone, allowForm } = this.state

		return (
			<div>
				{(() => {
					if (allowForm) {
						return (
							<div className="guest-form">
								<div className="area-form">
									<form onSubmit={this._handleSubmit}>
										<div className="form-header">
											<div className="header-title">Enter your information</div>
											<div className="header-submit">
												<button type="submit">Submit</button>
											</div>
										</div>
										<div className="form-fields">
											<div className="single-filed">
												<span className="filed-label">First name</span>
												<input type="text" name="firstName" className="filed-input"
													value={firstName}
													onChange={this._changeHandler} required />
											</div>
											<div className="single-filed">
												<span className="filed-label">Last name</span>
												<input type="text" name="lastName" className="filed-input"
													value={lastName}
													onChange={this._changeHandler} required />
											</div>
											<div className="single-filed">
												<span className="filed-label">Email</span>
												<input type="email" name="email" className="filed-input"
													value={email}
													onChange={this._changeHandler} required />
											</div>
											<div className="single-filed">
												<span className="filed-label">Phone</span>
												<input type="tel" name="phone" className="filed-input"
													value={phone}
													onChange={this._changeHandler} required />
											</div>
										</div>
									</form>
								</div>
							</div>)
					}
				})()}
			</div>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	cardState: state.card,
})
const mapDispatchToProps = ({
	sendNotification,
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GuestForm))
