import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Collapsible from 'react-collapsible';

import { loadCardDetails, sendNotification } from '../../store/actions/cardActions'

const ref = React.createRef();

class TabType1 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: '',
			cardID: this.props.match.params.cardID,
			page: 1,
			password: '',
			error: {},
			highlighVideo: null,
			highlightPreview: null
		}
	}

	componentDidMount() {
		// Send can open notification //
		let { cardID } = this.state

	}
	render() {
		let { cardState, pageData, auth, tabInfo } = this.props
		let { page } = this.state
		let { user: authUser } = auth

		let { cardID, highlighVideo, highlightPreview } = this.state
		let { selectedCard } = cardState

		let { contents: tabContents } = tabInfo

		return (
			<div className="single-tab type-1">
				<Collapsible
					onOpen={() => {
						this.props.sendNotification(
							{
								"card": tabInfo.CardID,
								"type": "tab",
								"type_id": tabInfo.TabID,
							}
						)
					}}
					trigger={tabInfo.tab_title}>
					{(() => {
						if (tabContents.content) {
							return (
								<video controls>
									<source src={tabContents.content} type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							)
						}
					})()}
				</Collapsible>
			</div>

		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	cardState: state.card,
})
const mapDispatchToProps = ({
	sendNotification,
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabType1))
