import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Collapsible from 'react-collapsible';

import { loadCardDetails, sendNotification } from '../../store/actions/cardActions'

const ref = React.createRef();

class TabType2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: '',
			cardID: this.props.match.params.cardID,
			page: 1,
			password: '',
			error: {},
			selectedHighlight: null,
			playHighlightVideo: null,
			highlighVideo: null,
			highlightPreview: null
		}
	}

	componentDidMount() {
		// Send can open notification //
		let { cardID } = this.state

		// set first video as selected //
		let { tabInfo } = this.props
		try {
			this.setState({
				selectedHighlight: tabInfo.contents[0]
			})
		} catch (error) {

		}

	}
	render() {
		let { cardState, pageData, auth, tabInfo } = this.props
		let { page } = this.state
		let { user: authUser } = auth

		let { cardID, selectedHighlight, playHighlightVideo } = this.state
		let { selectedCard } = cardState
		let { contents: tabContents } = tabInfo


		return (
			<div className="single-tab type-2">
				<Collapsible
					onOpen={() => {
						this.props.sendNotification(
							{
								"card": tabInfo.CardID,
								"type": "tab",
								"type_id": tabInfo.TabID,
							}
						)
					}}
					trigger={tabInfo.tab_title}>
					<div className="list-videos">
						<div className="video-preview">
							{(() => {
								if (selectedHighlight != null) {
									if (playHighlightVideo != null) {
										return (
											<video controls key={selectedHighlight.ID} autoPlay="true">
												<source src={selectedHighlight.content.video} type="video/mp4" />
												Your browser does not support the video tag.
											</video>
										);
									} else {
										return (
											<div className="preview-photo" onClick={() => { this.setState({ playHighlightVideo: true }) }}>
												<img src={selectedHighlight.content.thumbnail} alt="" />
											</div>
										);
									}
								}
							})()}
						</div>
						{(() => {
							if (tabContents) {
								return (
									<div className="playlist">
										{
											tabContents ? tabContents.map(contentInfo => {
												return (
													<div key={contentInfo.ID} className="item-single" onClick={() => { this.setState({ selectedHighlight: contentInfo, playHighlightVideo: true }) }}>
														<img src={contentInfo.content.thumbnail} alt="" />
													</div>
												)
											}) : ''
										}
									</div>
								)
							}
						})()}
					</div>
				</Collapsible>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	cardState: state.card,
})
const mapDispatchToProps = ({
	sendNotification,
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabType2))
