import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Collapsible from 'react-collapsible';

import { loadCardDetails, sendNotification } from '../store/actions/cardActions'
import TabType1 from '../components/card/tabTypes1'
import TabType2 from '../components/card/tabTypes2'
import TabType3 from '../components/card/tabTypes3'

import GuestForm from '../components/guestForm'

import setPageData from '../store/actions/setPageData'

import logoImage from '../assets/images/logo.png'

const ref = React.createRef();

class cardDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: '',
			cardID: this.props.match.params.cardID,
			page: 1,
			password: '',
			error: {},
			highlighVideo: null,
			highlightPreview: null
		}
	}


	setHighlighPreview() {
		console.log('working');
		this.setState({ highlighVideo: "https://firebasestorage.googleapis.com/v0/b/sap-app-8318e.appspot.com/o/video_thumbnail%2Fimage_cropper_A6C402A8-48C3-4341-8B77-7E27E6259C1F-10146-000008F8D003E8A9.jpg?alt=media&token=33d20508-53fb-4263-b498-425f87439385" })
	}
	setHighlighVideo(videoID) {
		var videoURL = "https://firebasestorage.googleapis.com/v0/b/sap-app-8318e.appspot.com/o/tab_video%2Ftrim.82893A48-55F4-4814-BD15-822CE6EA8439.MOV?alt=media&token=8159e2d6-2e1e-4b62-b565-cba307b82a25&ask" + videoID;
		this.setHighlighPreview()
		this.setState({ highlighVideo: videoURL })
	}

	loadCardDetails() {
		let { cardID } = this.state
		this.props.loadCardDetails({ ID: cardID })
	}
	sendNotification(postData) {
		this.props.sendNotification(postData)
	}

	componentDidMount() {
		this.props.setPageData({
			title: 'Invoice Details',
			pageID: 'invoice'
		})

		// Send can open notification //
		let { cardID } = this.state

		this.sendNotification({
			"card": cardID,
			"type": "card",
			"type_id": cardID,
		});

		this.loadCardDetails()
	}
	render() {
		let tabColor = "3072dd";
		let { cardState, pageData, auth } = this.props
		let { page } = this.state
		let { user: authUser } = auth

		let { cardID, highlighVideo, highlightPreview } = this.state
		let { selectedCard } = cardState
		console.log(selectedCard)

		let guestUser = true;
		try {
			tabColor = selectedCard.card_meta.tab_color;
			guestUser = JSON.parse(localStorage.getItem('guestUser'))
			guestUser = guestUser ? guestUser : null;
		} catch (error) {

		}

		return (
			<>
				<Helmet> <title>{selectedCard.card_name}</title> </Helmet>

				<style>{".Collapsible__trigger{ background-color:#" + tabColor + " !important; }"}</style>

				<div className="App view-card">

					<div className="card-header">
						<div className="header-main">
							<div className="header-logo">
								<img src={logoImage} alt="SAP Logo" />
							</div>
						</div>
					</div>
					<div className="header-shape"></div>

					<div className="after-header"></div>

					<div className="card-content">

						{(() => {
							if (!selectedCard.ID) {
								return (<div className="card-loading">Loading...</div>);
							} else {
								return (<>

									<div className="header-area">
										<div className="profile-cover">
											<img src={selectedCard.card_cover} alt={selectedCard.card_name} />
										</div>
										<div className="profile-photo">
											<img src={selectedCard.card_photo} alt={selectedCard.card_name} />
										</div>
									</div>

									<div className="basic-area">
										{(() => {
											if (selectedCard.card_name) {
												return (
													<div className="info-name">{selectedCard.card_name}</div>
												)
											}
										})()}
										{(() => {
											if (selectedCard.card_title) {
												return (
													<div className="info-position">{selectedCard.card_title}</div>
												)
											}
										})()}
										{(() => {
											if (selectedCard.card_address) {
												return (
													<div className="info-location">{selectedCard.card_address}</div>
												)
											}
										})()}
									</div>

									{(() => {
										if (selectedCard.card_phone || selectedCard.card_text || selectedCard.card_email) {
											return (
												<div className="action-area">
													{(() => {
														if (selectedCard.card_phone) {
															return (
																<span className="action-single" onClick={() => {
																	this.sendNotification(
																		{
																			"card": cardID,
																			"type": "custom",
																			"custom_title": "tab on call button",
																			"type_id": cardID,
																		}
																	)
																	window.open('tel:' + selectedCard.card_phone, '_blank')

																}}>
																	<i className="fa fa-phone action-icon" aria-hidden="true"></i>
																	<div className="action-name">phone</div>
																</span>
															)
														}
													})()}
													{(() => {
														if (selectedCard.card_text) {
															return (
																<span className="action-single" onClick={() => {
																	this.sendNotification(
																		{
																			"card": cardID,
																			"type": "custom",
																			"custom_title": "tab on text button",
																			"type_id": cardID,
																		}
																	)
																	window.open('sms:' + selectedCard.card_text, '_blank')
																}}>
																	<i className="fa fa-comment action-icon" aria-hidden="true"></i>
																	<div className="action-name">text</div>
																</span>)
														}
													})()}
													{(() => {
														if (selectedCard.card_email) {
															return (
																<span className="action-single" onClick={() => {
																	this.sendNotification(
																		{
																			"card": cardID,
																			"type": "custom",
																			"custom_title": "tab on mail button",
																			"type_id": cardID,
																		}
																	)
																	window.open('mailto:' + selectedCard.card_email)
																}}>
																	<i className="fa fa-envelope action-icon" aria-hidden="true"></i>
																	<div className="action-name">mail</div>
																</span>)
														}
													})()}
												</div>
											)
										}
									})()}
									<div className="tabs-area">
										{
											selectedCard.tab ? selectedCard.tab.map(tabInfo => {
												if (tabInfo.tab_type == 1) {
													return <TabType1 tabInfo={tabInfo} />
												}
												if (tabInfo.tab_type == 2) {
													return <TabType2 tabInfo={tabInfo} />
												}
												if (tabInfo.tab_type == 3) {
													return <TabType3 tabInfo={tabInfo} />
												}
											}) : ''
										}
									</div>

								</>);
							}
						})()}
					</div>

					{(() => {
						if (!guestUser) {
							return <GuestForm />
						}
					})()}
				</div>
			</>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	cardState: state.card,
})
const mapDispatchToProps = ({
	loadCardDetails,
	sendNotification,
	setPageData,
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(cardDetails))