import axios from 'axios'
import * as Types from './types'

export const loadCardDetails = (data) => dispatch => {

	// Set exists minified info first //
	axios.post('card/details/', data)
		.then((res) => {
			let responseData = res.data

			dispatch({
				type: Types.CARD_DETAILS,
				payload: { cardDetails: responseData.data }
			})
		})
		.catch(error => {

		})
}

export const sendNotification = (data) => dispatch => {
	// Set exists minified info first //
	let guestUser = JSON.parse(localStorage.getItem('guestUser'))
	data["guestUser"] = guestUser;
	console.log(data)


	axios.post('/essential/notification/card', data)
		.then((res) => {

		})
		.catch(error => {

		})
}