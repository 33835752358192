import * as Types from '../actions/types'

const init = {
	data: null,
	pagination: {},
	error: {},
	selectedCard: {},
	success: '',
}

const cardReducer = (state = init, action) => {
	switch (action.type) {

		case Types.CARD_DETAILS: {
			return {
				...state,
				selectedCard: action.payload.cardDetails
			}
		}
		default: return state
	}
}

export default cardReducer